import slide1 from "./../../assets/img/slide1.png";
import slide2 from "./../../assets/img/slide2.png";
import slide3 from "./../../assets/img/slide3.png";
import slide4 from "./../../assets/img/slide4.png";
import slide5 from "./../../assets/img/slide5.png";
import slide6 from "./../../assets/img/slide6.png";
import tutwiri from "./../../assets/img/Tutwuri-Handayani.png";
import unes_s from "./../../assets/img/unes-small.png";

import kampus_merdeka from "./../../assets/img/kampus-merdeka.png";
import fakultas from '../../json/fakultas.json'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import HomeLayout from '../../layout/HomeLayout';
import { NavLink } from 'react-router-dom';
const Home = () => {

    const settings = {
        fade: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        // adaptiveHeight: true,
        pauseOnHover: false,
        // variableWidth: true


    };



    return (
        <>
            <HomeLayout>

                <div className=" mt-16 md:grid md:grid-cols-2 md:gap-2 grid-cols-none   w-full relative bg-white">
                    <div className="flex bg-white flex-col justify-center items-start   w-full h-full md:h-[720px] gap-2.5 md:pl-10 lg:pl-20 md:py-32 py-12">
                        <div className="flex flex-col justify-start items-start   w-full pl-12">
                            <div className="flex flex-col justify-start items-start self-stretch   h-[269px]">
                                <div className="flex flex-col justify-center items-start   h-[150px] md:h-[206px] w-full relative gap-2">
                                    <p className="self-stretch   w-full text-3xl lg:text-[50px] font-bold text-left text-slate-900">
                                        SELAMAT DATANG
                                    </p>
                                    <p className="w-full md:text-2xl text-left text-black">
                                        Calon Mahasiswa UNES-AAI Tahun AKADEMIK 2024/2025
                                    </p>
                                    <p className=" w-full  text-xl text-left text-slate-700">
                                        Daftarkan dirimu sekarang juga
                                    </p>
                                </div>
                                <div className="md:grid grid-cols-none md:grid-cols-3 md:gap-3">
                                    <div className='col-span-2  py-2 flex justify-center items-center'>
                                        <NavLink to={'/daftar'}>
                                            <div
                                                className=" mr-1 flex justify-center items-center   relative overflow-hidden gap-2 px-5 py-[13px] rounded-[30px] bg-[#833a3c]"
                                                style={{ boxShadow: "0px 1px 2px 0 rgba(15,23,42,0.06)" }}>
                                                <p className="  text-base font-medium text-left text-white">DAFTAR</p>
                                                <svg width={22} height={23} viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg" className="  w-[22px] h-[22px] relative" preserveAspectRatio="none">
                                                    <path d="M13.2275 5.93579L18.7917 11.5L13.2275 17.0641" stroke="white" strokeWidth="1.4" strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M3.2085 11.5H18.636" stroke="white" strokeWidth="1.5" strokeMiterlimit={10} strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </div>
                                        </NavLink>
                                        <a href={require('../../assets/pdf/BROSUR PMB UNES 2024.pdf')} download>
                                            <div className=" ml-1 flex justify-center items-center   relative overflow-hidden gap-2 px-5 py-[13px] rounded-[30px] bg-[#754546]" style={{ boxShadow: "0px 1px 2px 0 rgba(15,23,42,0.06)" }}>
                                                <p className="  text-base font-medium text-left text-white">
                                                    UNDUH BROSUR
                                                </p>
                                            </div>
                                        </a>
                                    </div>
                                    <div className='  flex justify-start py-2 w-full items-center'>
                                        <img src={tutwiri} className=" p-1  w-[37.19px] h-[36.5px] object-contain" alt=' tutwuri' />
                                        <img src={unes_s} className=" p-1  w-9 h-[38px] object-contain" alt='unes' />
                                        <img src={kampus_merdeka} className=" p-1  w-[66px] h-[35.32px] object-contain" alt='kampus merdeka' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className=" flex md:mt-26 flex-col justify-center items-center w-full h-full md:h-[720px] relative overflow-hidden">
                        <div className="w-full   justify-center items-center ">
                            <Slider {...settings}>
                                <img src={slide1} className="  object-contain" alt='slide1' />
                                <img src={slide2} className="  object-contain" alt='slide2' />
                                <img src={slide6} className="  object-contain" alt='slide6' />
                                <img src={slide3} className="  object-contain" alt='slide3' />
                                <img src={slide4} className="  object-contain" alt='slide4' />
                                <img src={slide5} className="  object-contain" alt='slide5' />
                            </Slider>
                        </div>
                    </div>
                </div>


                <div id='about' className=' flex justify-center '>
                    <div className="flex mt-8 md:mx-8 mx-4 flex-col justify-start items-center w-full h-full relative  bg-white" style={{ boxShadow: "0px 4px 4px 0 rgba(0,0,0,0.25)" }}>
                        <div className="container flex flex-col px-6 py-10 mx-auto space-y-6 lg:h-[32rem] lg:py-16 lg:flex-row lg:items-center">
                            <div className="w-full lg:w-1/2">
                                <div className="lg:max-w-lg">
                                    <h1 className="text-2xl font-bold tracking-wide text-gray-800  lg:text-4xl">
                                        UNIVERSITAS EKASAKTI
                                        <div className='text-2xl'>
                                            Akademi Akuntansi Indonesia
                                        </div>
                                    </h1>

                                    <div className="mt-8 space-y-5">
                                        <p>
                                            Merupakan salah satu perguruan tinggi swasta yang berada di bawah naungan Yayasan Perguruan Tinggi Indonesia (YPTI). Kini Yayasan Perguruan Tinggi Padang (YPTP, sejak 2008). Perguruan Tinggi ini didirikan dan dipimpin oleh Prof Dr H. Andi Mustari Pide, S.H (Alm), bersama dengan DR.Hj.Erawati Toelis, M.M (Alm), berdiri pada bulan April 1973 di Padang. Pada awalnya tahun 1973 itu, YPTI mendirikan Akademi Akuntansi Indonesia Padang.
                                        </p>
                                    </div>
                                </div>


                            </div>

                            <div className="flex items-center justify-center w-full h-96 lg:w-1/2">
                                <video controls className="object-cover w-full h-full mx-auto rounded-md lg:max-w-2xl" autoPlay loop muted>
                                    <source src="https://unespadang.ac.id/gaya/video/Universitas%20Ekasakti%20AAI%20(Akademi%20Akuntansi%20Indonesia).mp4" type="video/mp4" />
                                </video>
                            </div>
                        </div>
                    </div>
                </div>

                <div id='biaya' className=' flex justify-center '>
                    <div className="flex my-8 md:mx-8 mx-4 flex-col justify-start items-center w-full h-full relative  bg-white" style={{ boxShadow: "0px 4px 4px 0 rgba(0,0,0,0.25)" }}>
                        <p className=" py-10 w-full text-center text-[#464646]">
                            <span className=" w-full text-[21px] font-light text-center text-[#464646]">
                                FAKULTAS DAN PROGRAM STUDI
                            </span>
                            <br />
                            <span className="w-full text-[32px] font-medium text-center text-[#464646]">
                                TERAKREDITASI B
                            </span>
                        </p>
                        <div className="flex md:px-20 flex-col justify-start items-start   w-full  ">
                            <div className=" md:grid md:grid-cols-3 grid-cols-none w-full h-auto   ">
                                {/* card */}
                                {
                                    fakultas.map((fk, index) => (
                                        <div key={index} className="flex mx-4 my-4 card  justify-center">
                                            <div className="flex w-full flex-col shadow-md rounded-lg border border-[#833a3a] justify-end items-center h-full bg-white">
                                                <div className="flex w-full flex-col justify-start items-center   h-full relative">
                                                    <div className=" w-full h-full">
                                                        <div className="flex flex-col justify-center items-center w-full h-[90px] pt-4 px-4 ">
                                                            <div className="flex justify-center items-center   w-full rounded-t-lg  h-full relative bg-[#8b4648]">
                                                                <div className="flex-grow leading-7  text-[22px] font-bold text-center text-white">
                                                                    {fk.name}
                                                                </div>
                                                            </div>
                                                            <div className="flex flex-col justify-center items-center   w-full overflow-hidden pb-2">
                                                                <div className="flex flex-col justify-start items-start self-stretch   h-0.5 relative gap-2.5 py-px bg-[#b29091]">
                                                                    <div className="self-stretch   h-0" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-col justify-start items-center w-full px-4 h-full min-h-[50px] ">
                                                            {/* list prodi */}
                                                            {fk.prodi?.map((prodi, index) => (
                                                                <div key={index} className=" shadow-md mb-2  w-full h-auto ">
                                                                    <NavLink to={prodi.redirect} >
                                                                        <div className="flex flex-col justify-start items-start w-full">
                                                                            <div className="flex justify-start items-center self-stretch   relative gap-1.5 px-6 py-2 bg-[#833a3c]/[0.04]">
                                                                                <div className="flex flex-col justify-center items-center   h-[18px] w-[18px] relative gap-2.5 rounded-3xl">
                                                                                    <svg width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className="  w-[18px] h-[18px]" preserveAspectRatio="none">
                                                                                        <circle cx={9} cy="8.99792" r={8} stroke="#833A3C" strokeWidth={2} />
                                                                                    </svg>
                                                                                </div>
                                                                                <p className="flex-grow w-full leading-5 font-bold text-left text-gray-500 ">
                                                                                    {prodi.prodiName} {prodi.jenjang}
                                                                                </p>
                                                                            </div>
                                                                            <div className="flex justify-start items-center self-stretch   h-0 relative gap-6 pl-[38px] pr-4 bg-[#f3f5f6]">
                                                                                <svg width={224} height={2} viewBox="0 0 224 2" fill="none" xmlns="http://www.w3.org/2000/svg" className="flex-grow" preserveAspectRatio="none">
                                                                                    <path d="M0 0.997925H224" stroke="#F3F5F6" />
                                                                                </svg>
                                                                            </div>
                                                                        </div>
                                                                        <div className="flex flex-col justify-center items-start w-full ">
                                                                            <div className="flex justify-end items-center w-full  px-4  py-2">
                                                                                <div className=' flex font-light items-center'>
                                                                                    <div className="flex-grow w-full  text-left text-[#3c3c43]">
                                                                                        Terakreditasi
                                                                                    </div>
                                                                                    <div className='bg-green-500 font-bold  flex items-center justify-center rounded-full w-11 h-8   text-lg text-white'>
                                                                                        {prodi.Akreditasi}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="flex justify-start items-start self-stretch   relative gap-6 pl-[72px] pr-4 bg-[#f3f5f6]">
                                                                                <svg width={190} height={2} viewBox="0 0 190 2" fill="none" xmlns="http://www.w3.org/2000/svg" className="flex-grow" preserveAspectRatio="none">
                                                                                    <path d="M0 0.997925H190" stroke="#F3F5F6" />
                                                                                </svg>
                                                                            </div>
                                                                        </div>
                                                                    </NavLink>
                                                                </div>
                                                            ))}

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex flex-col justify-end items-center   h-[185px] w-full px-[7px]">
                                                    <div className="flex flex-col justify-start items-start   w-[282px] overflow-hidden py-2">
                                                        <div className="flex flex-col justify-start items-start self-stretch   h-0.5 relative gap-2.5 py-px bg-[#833a3c]">
                                                            <div className="self-stretch   h-0" />
                                                        </div>
                                                    </div>
                                                    <div className="flex flex-col justify-end items-center   h-[84px] w-full gap-2 px-3 pb-2">
                                                        <div className="flex justify-center items-center self-stretch   h-12 relative gap-2 px-6 rounded-[30px] bg-[#e68d2b]">
                                                            <p className=" t  text-2xl font-bold text-center text-white">
                                                                Rp. {fk.biaya.toLocaleString()}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <a href={fk.redirect} className="flex flex-col justify-end items-center   h-[84px] w-full gap-2 pb-8 px-3 ">
                                                        <div className="flex justify-center items-center self-stretch   h-12 relative gap-2 px-6 rounded-[30px] bg-[#874244]">
                                                            <p className="  text-1xl  text-center text-white">
                                                                Lihat Fakultas
                                                            </p>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                                {/* end card */}
                            </div>
                        </div>
                    </div>
                </div>


            </HomeLayout>

        </>
    )
}

export default Home
